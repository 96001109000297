// 临时数据管理 api

export const DATA_TEMP_KEY = 'sensors-params';
export const WINDOW_PARAMS_KEY = 'window-params';

/**
 * 设置临时数据
 */
export const postDataTemp = defineFindApi({
  url: '/community/v1/web/front-cache/:key',
  method: HttpMethod.POST,
  paramTy: ParamTy.Json(),
  respTy: RespTy.Json<any>(),
});

/**
 * 获取临时数据
 */
export const getDataTemp = defineFindApi({
  url: '/community/v1/web/front-cache/:key',
  method: HttpMethod.GET,
  paramTy: ParamTy.Query(),
  respTy: RespTy.Json<any>(),
});

/**
 * 删除临时数据
 */
export const deleteDataTemp = defineFindApi({
  url: '/community/v1/web/front-cache/:key',
  method: HttpMethod.DELETE,
  paramTy: ParamTy.Query(),
  respTy: RespTy.Json<any>(),
});
